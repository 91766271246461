
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Helmet from 'react-helmet';
import laptop from '../../../../static/img/laptop.jpg';
import background from '../../../../static/img/services/agency.jpg';
import Card from '../../../components/Card.js';
import ContactBox from '../../../components/ContactBox.js';
import Hero from '../../../components/Hero.js';
import Navbar from '../../../components/Navbar';
import DefaultLayout from '../../../layouts/Default';
import '../../../sass/pages/services/sirc.scss';
import Oliva from '../../../../static/img/clients/oliva.svg';

const links = [
  { label: (<><FontAwesomeIcon icon={['fas', 'arrow-left']} /> VOLVER</>), href: "/sections/contable" },
  { label: "CONTACTAR", href: "/services/contable/sirc#contact-box"}
];

const hero = {
  title: 'RENDICIONES DE CUENTAS',
  background
};


export default () => (
  <DefaultLayout>
    <Helmet>
      <title>VITECH - Rendiciones de Cuentas Sistema SIRC</title>
    </Helmet>

    <Navbar kind="secondary" links={ links } />

    <Hero {...hero } />

    <br /><br />

    <section id="rendiciones">
      <div className="container-min">
        <header id="rendiciones-header">
          <article className="info">
            <h2>SIRC & VITECH</h2>
            <p>El servicio de Gestoría de VITECH Soluciones Integrales para Entidades en Convenio con el Estado comprende el procesamiento de toda la documentación física correspondiente a la rendición de los fondos recibidos y la creación de la Liquidación de Rendición de Cuentas.</p>
          </article>
          <article className="laptop">
            <img src={ laptop } alt="SIRC VITECH" />
          </article>
        </header>

        <br /><br />

        <section id="rendiciones-services" className="card-list">
          <Card title="Ingreso de Partidas" body="Ingreso de los fondos recibidos del ente correspondiente al sistema." />
          <Card title="Comprobantes" body="Registro de los comprobantes de gastos (salariales, no salariales e inversiones) en el sistema." />
          <Card title="Liquidaciones Salariales" body="Creación en sistema de liquidaciones salariales: mensuales, aguinaldos, salarios vacacionales, liquidaciones finales por egreso." />
          <Card title="Solicitud de Avales" body="Creación de solicitudes de aval por sistema para gastos realizados o futuros." />
          <Card title="Respuesta a Objeciones" body="Análisis y respuesta a las objeciones sobre las rendiciones de cuentas en tiempo real." />
          <Card title="Proyección de Gastos" body="Análisis de gastos realizados por ejercicio e incorporación de actividades proyectadas para la presentación del presupuesto anual." />
        </section>
      </div>

      <br />
      
      <div className="container-min">
        <div className="pad-40 shadow text-center lucho">
          <img src={ Oliva } alt="Logo Luis Oliva Monfort" width="80" />
          <div className="olivaname">
            <div>Luis Oliva Monfort</div>
            <div>Contador asociado</div>
          </div>
        </div>
      </div>

      <br />

      <div className="container-min">
        <ContactBox />
      </div>

    </section>
  </DefaultLayout>
);