
import React from 'react';

export default ({ title, body }) => (
  <article className="card">
    <h3 className="card-title">{ title }</h3>

    <section className="card-body">
      { body }
    </section>
  </article>
)